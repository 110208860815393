export const awsconfig = {
  aws_project_region: 'eu-west-1',
  userPoolId: 'eu-west-1_g44g5IoBS',
  userPoolWebClientId: '78bhk8oubum4na56gpugcdb2jn',
};

export const environment = {
  name: 'development',
  apiUrl: 'https://api.dev.spice.quantis.solutions',
  whitelistedDomains: ['api.dev.spice.quantis.solutions'],
  production: false,
  enableSentry: true,
};
